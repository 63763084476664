import useFeatureToggle from '@guestyci/feature-toggle-fe/useFeatureToggle';

import { DISCO_BE_TOTAL_PRICE_ENGINES } from 'constants/featureToggleNames';
import useSearchValues from 'hooks/useSearchValues';
import useIsGroupReservation from 'hooks/useIsGroupReservation';

const useTotalPrice = ({ beforeTaxesPrice = 0, totalPrice = 0 } = {}) => {
  const [, isTotalPriceFtEnabled] = useFeatureToggle(DISCO_BE_TOTAL_PRICE_ENGINES);
  const { checkInDateLocalized, checkOutDateLocalized } = useSearchValues();
  const { isGroupReservationEnabled } = useIsGroupReservation();
  const isTotalPriceEnabled = !!(
    isTotalPriceFtEnabled &&
    checkInDateLocalized &&
    checkOutDateLocalized &&
    !isGroupReservationEnabled
  );

  return {
    isTotalPriceEnabled,
    price: isTotalPriceEnabled && totalPrice ? totalPrice : beforeTaxesPrice,
  };
};

export default useTotalPrice;
