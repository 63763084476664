'use client';

import { memo, useRef, useState } from 'react';
import { Circle, GoogleMap, InfoBox, Marker, MarkerClusterer, LoadScript } from '@react-google-maps/api';
import createStyles from '@guestyci/foundation/createStyles';

import MapPropertiesCarousel from 'components/MapPropertiesCarousel';

import useOutsideClick from 'hooks/useOutsideClick';
import useGetPathToNavigate from 'hooks/useGetPathToNavigate';

import { CIRCLE_SETTINGS, GOOGLE_MAP_KEY, MAP_SETTINGS, MAP_STYLING, MARKER_SETTINGS } from 'constants/map';

import { iconUrl } from './icon';

class LoadScriptOnlyIfNeeded extends LoadScript {
  componentDidMount() {
    const cleaningUp = true
    const isBrowser = typeof document !== "undefined"
    const isAlreadyLoaded =
      window.google &&
      window.google.maps &&
      document.querySelector('body.first-hit-completed');
    if (!isAlreadyLoaded && isBrowser) {
      if (window.google && !cleaningUp) {
        console.error("google api is already presented")
        return
      }

      this.isCleaningUp().then(this.injectScript)
    }

    if (isAlreadyLoaded) {
      this.setState({ loaded: true })
    }
  }
}

const { DEFAULT_ZOOM, DEFAULT_CENTER, CIRCLE_ZOOM, CLUSTER_ZOOM, MAX_ZOOM, SHOW_BLUE_ICONS } = MAP_SETTINGS;

const containerMarkerStyle = {
  height: '73vh',
  width: '100%',
};

const containerCircleStyles = {
  width: '100%',
  height: '300px',
};

const infoBoxWidth = 380;
const infoBoxOffsetX = (-infoBoxWidth - 130) / 2;
const infoBoxOffsetY = -520;
const infoBoxWithMultiplePropertiesOffsetY = -530;

const useStyles = createStyles(() => ({
  infoBoxContainer: {
    transform: 'scale(0.7)',
  },
}));


const MARKER_CIRCLE_SETTINGS = {
  fillOpacity: MARKER_SETTINGS.OPACITY.CIRCLE,
  scale: 1,
  strokeColor: MARKER_SETTINGS.STROKE_COLOR.CIRCLE,
  strokeWeight: MARKER_SETTINGS.STROKE_WEIGHT,
};

const MARKER_DEFAULT_SETTINGS = {
  fillOpacity: MARKER_SETTINGS.OPACITY.NO_CIRCLE,
  scale: 1,
  strokeColor: MARKER_SETTINGS.STROKE_COLOR.NO_CIRCLE,
  strokeWeight: MARKER_SETTINGS.STROKE_WEIGHT,
};


const getMarkerDefaultSettings = (isCircle) => isCircle ? MARKER_CIRCLE_SETTINGS : MARKER_DEFAULT_SETTINGS;
const getValuesBasedOnMultipleListings = (isMultipleListingsAtTheSamePosition) => isMultipleListingsAtTheSamePosition ? {
  url: iconUrl,
  labelOrigin: new window.google.maps.Point(17, 15),
} : {
  path: MARKER_SETTINGS.PATH,
  labelOrigin: new window.google.maps.Point(0, 7),
}

const getMarkerSettings = ({ isCircle, isMultipleListingsAtTheSamePosition }) => {
  const markerSettings = getMarkerDefaultSettings(isCircle);
  return {
    ...markerSettings,
    ...getValuesBasedOnMultipleListings(isMultipleListingsAtTheSamePosition),
  }
};

const MapContainer = ({ markers = [], circle = false, center, containerStyles }) => {
  const { infoBoxContainer } = useStyles();
  const { locale = 'en-US' } = useGetPathToNavigate();
  const [showInfoBox, setShowInfoBox] = useState(false);
  const [infoBoxData, setInfoBoxData] = useState(null);
  const [preventClosePropertyCard, setPreventClosePropertyCard] = useState(false);
  const infoBoxContentRef = useRef(null);
  const mapWrapperRef = useRef(null);

  useOutsideClick({
    elementRef: infoBoxContentRef,
    wrapperRef: mapWrapperRef,
    handler: () => setShowInfoBox(false),
    preventHandler: preventClosePropertyCard,
  });

  const onMarkerClick = (similarListings) => {
    setInfoBoxData(null);
    setShowInfoBox(true);
    setInfoBoxData(similarListings);
  };

  const togglePreventClosePropertyCard = () => {
    setPreventClosePropertyCard(!preventClosePropertyCard);
  };

  const renderMarkerCluster = () => (
    <>
      <MarkerClusterer defaultMaxZoom={CLUSTER_ZOOM} maxZoom={CLUSTER_ZOOM} options={SHOW_BLUE_ICONS}>
        {(clusterer) =>
          markers.map((marker) => {
            const { position, properties } = marker;
            const isMultipleListingsAtTheSamePosition = properties.length > 1;
            const label = isMultipleListingsAtTheSamePosition ? {
              text: properties.length.toString(),
              fontSize: '11px',
            } : '';
            return properties?.map(({ _id }) => {
              return (
                (
                  <Marker
                    animation={2}
                    key={`${_id}_${position.lat + position.lng}`}
                    position={position}
                    style={{ position: 'relative' }}
                    clusterer={clusterer}
                    onClick={() => onMarkerClick(marker)}
                    label={label}
                    icon={{ ...getMarkerSettings({ isCircle: circle, isMultipleListingsAtTheSamePosition }) }}
                  />
                )
              );
            });
          })}
      </MarkerClusterer>
      {showInfoBox && infoBoxData?.position && window.google.maps && (
        <InfoBox
          position={infoBoxData.position}
          options={{
            pixelOffset: new window.google.maps.Size(
              infoBoxOffsetX,
              infoBoxData.properties.length > 1 ? infoBoxWithMultiplePropertiesOffsetY : infoBoxOffsetY
            ),
            closeBoxURL: '',
            boxStyle: {
              minWidth: '510px',
              height: '550px',
              overflow: 'hidden',
            },
            enableEventPropagation: true,
          }}
        >
          <div ref={infoBoxContentRef} className={infoBoxContainer}>
            <MapPropertiesCarousel properties={infoBoxData.properties} />
          </div>
        </InfoBox>
      )}
    </>
  );

  const renderMarkerCircle = () => <Circle center={center} options={CIRCLE_SETTINGS} />;

  const onLoad = (mapInstance) => {
    if (center) return;
    const bounds = new window.google.maps.LatLngBounds();
    markers.forEach(({ position: { lat, lng } }) => {
      if (!lat || !lng) return;
      bounds.extend(new window.google.maps.LatLng(lat, lng));
      mapInstance.fitBounds(bounds);
    });
  };
  const renderMap = () => {
    const zoom = circle ? CIRCLE_ZOOM : DEFAULT_ZOOM;
    const containerStyle = containerStyles || (circle ? containerCircleStyles : containerMarkerStyle);
    return (
      <LoadScriptOnlyIfNeeded googleMapsApiKey={GOOGLE_MAP_KEY} language={locale}>
        <div ref={mapWrapperRef}>
          <GoogleMap
            zoom={zoom}
            center={center || DEFAULT_CENTER}
            mapContainerStyle={containerStyle}
            onLoad={onLoad}
            options={{
              styles: MAP_STYLING,
              maxZoom: MAX_ZOOM,
            }}
            onDragStart={togglePreventClosePropertyCard}
            onDragEnd={togglePreventClosePropertyCard}
          >
            {markers && !circle && renderMarkerCluster()}
            {circle && renderMarkerCircle()}
          </GoogleMap>
        </div>
      </LoadScriptOnlyIfNeeded>
    );
  };

  return renderMap();
};

export default memo(MapContainer);
