import { useContext, useMemo } from 'react';

import { useGetCurrencyRateQuery } from 'hooks/useGetCurrencyRate';
import { getPriceStr } from 'utils';
import { CurrencyContext } from 'context/CurrencyContext';
import Placeholder from '@guestyci/foundation/Placeholder';

const PriceConverter = ({ currency, amount, roundPrice = 0 }) => {
  const { selectedCurrency } = useContext(CurrencyContext);
  const { data, status, isLoading, isSuccess } = useGetCurrencyRateQuery({ from: currency, to: selectedCurrency });
  const calculatedAmount = isSuccess ? data?.rate * amount : amount;
  const price = useMemo(() => {
    const roundedPrice = roundPrice ? calculatedAmount?.toFixed(roundPrice) : Math.round(calculatedAmount);
    return getPriceStr(roundedPrice, selectedCurrency);
  }, [calculatedAmount, selectedCurrency, roundPrice]);

  if (status === 'loading' || isLoading) {
    <Placeholder className="cell-placeholder height-10 w-fill" />
  }

  return <span>{price}</span>;
};

export default PriceConverter;
