/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/jsx-props-no-spreading */
import { useRef } from 'react';
import Slider from 'react-slick';
import cn from 'classnames';

import createStyles from '@guestyci/foundation/createStyles';
import TextField from '@guestyci/foundation/TextField';
import t from '@guestyci/localize/t.macro/t.macro';

import SmallPropertyCard from 'components/SmallPropertyCard';
import Icon from '../Icon';


const useStyles = createStyles(() => ({
  root: {
    '& .small-property-card > div > div': {
      borderRadius: ({ withNavigation }) => withNavigation && '0 0 10px 10px',
    },
  },
  featuredListingsCarousel: {
    width: '510px',
    '& .slick-slider': {
      display: 'flex',
      justifyContent: 'center',
      width: '100%',
    },
    '& .slick-slide > div': {
      display: 'flex',
      justifyContent: 'center',
    },
    '& .slick-list': {
      overflowY: 'visible',
      overflowX: 'hidden',
      height: 570,
    },
  },
  arrow: {
    cursor: 'pointer',
    height: 60,
    width: 60,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  navigationWrapper: {
    height: '65.5px',
    display: 'flex',
  },
  navigation: {
    display: 'inline-flex',
    alignItems: 'center',
    width: '74.8%',
    marginLeft: '12.6%',
    justifyContent: 'space-between',
    background: '#3E4E5D',
    borderRadius: '10px 10px 0px 0px',
    padding: '15px 0',
  },
}));

const CustomArrow = ({ onClick, className, icon }) => {
  const { arrow } = useStyles();
  return (
    <div className={cn(arrow, className)} onClick={onClick}>
      <Icon icon={icon} size={35} />
    </div>
  );
};

const sliderSettings = {
  infinite: true,
  draggable: false,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  lazyLoad: true,
  arrows: false,
};

const MapPropertiesCarousel = ({ properties }) => {
  const { root, featuredListingsCarousel, navigationWrapper, navigation } = useStyles({
    withNavigation: properties.length > 1,
  });
  const slider = useRef(null);
  const roomLabel = t(
    '{ num, plural, =0 {# Properties} =1 {# Property} few {# Properties} many {# Properties} other {# Properties}}',
    {
      num: properties.length,
    }
  );
  return (
    <div className={root}>
      <div className={navigationWrapper}>
        {properties.length > 1 && (
          <div className={navigation}>
            <CustomArrow icon="arrow" onClick={() => slider?.current?.slickPrev()} />
            <TextField variant="h2" color="white">
              {roomLabel}
            </TextField>
            <CustomArrow icon="arrow_next" onClick={() => slider?.current?.slickNext()} />
          </div>
        )}
      </div>
      <Slider ref={slider} {...sliderSettings} className={featuredListingsCarousel}>
        {properties.map((property) => (
          <SmallPropertyCard key={property._id} property={property} withArrow />
        ))}
      </Slider>
    </div>
  );
};

export default MapPropertiesCarousel;
