export const iconSvg = `
<svg width="34" height="42" viewBox="0 0 34 42" fill="none" xmlns="http://www.w3.org/2000/svg">
  <g filter="url(#filter0_ddd_2346_65583)">
    <path d="M17 4C13.8174 4 10.7652 5.21277 8.51477 7.37167C6.26433 9.53057 5 12.4588 5 15.5119C5 21.8626 17 36 17 36C17 36 29 21.8626 29 15.5119C29 12.4588 27.7357 9.53057 25.4853 7.37167C23.2349 5.21277 20.1826 4 17 4ZM17 20.1571C16.0413 20.1615 15.1029 19.8929 14.3035 19.3852C13.5042 18.8775 12.8799 18.1535 12.5098 17.3051C12.1397 16.4567 12.0405 15.522 12.2247 14.6195C12.4089 13.7169 12.8682 12.8872 13.5445 12.2353C14.2207 11.5834 15.0834 11.1388 16.0233 10.9577C16.9632 10.7766 17.938 10.8673 18.8241 11.2182C19.7103 11.5691 20.4679 12.1645 21.001 12.9289C21.5341 13.6933 21.8187 14.5922 21.8187 15.5119C21.8003 16.7283 21.2853 17.8893 20.3843 18.7453C19.4832 19.6013 18.2681 20.084 17 20.0897V20.1571Z" fill="#10275B"/>
    <rect x="9" y="7" width="16" height="16" rx="8" fill="white"/>
  </g>
  <defs>
    <filter id="filter0_ddd_2346_65583" x="0" y="0" width="34" height="42" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
      <feFlood flood-opacity="0" result="BackgroundImageFix"/>
      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
      <feOffset dy="1"/>
      <feGaussianBlur stdDeviation="2.5"/>
      <feComposite in2="hardAlpha" operator="out"/>
      <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.12 0"/>
      <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2346_65583"/>
      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
      <feOffset dy="2"/>
      <feGaussianBlur stdDeviation="1"/>
      <feComposite in2="hardAlpha" operator="out"/>
      <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.14 0"/>
      <feBlend mode="normal" in2="effect1_dropShadow_2346_65583" result="effect2_dropShadow_2346_65583"/>
      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
      <feMorphology radius="2" operator="erode" in="SourceAlpha" result="effect3_dropShadow_2346_65583"/>
      <feOffset dy="1"/>
      <feGaussianBlur stdDeviation="1"/>
      <feComposite in2="hardAlpha" operator="out"/>
      <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0"/>
      <feBlend mode="normal" in2="effect2_dropShadow_2346_65583" result="effect3_dropShadow_2346_65583"/>
      <feBlend mode="normal" in="SourceGraphic" in2="effect3_dropShadow_2346_65583" result="shape"/>
    </filter>
  </defs>
</svg>
`;

export const iconUrl = `data:image/svg+xml;charset=UTF-8,${encodeURIComponent(iconSvg)}`;
