import cn from 'classnames';
import { Link } from 'i18n/routing';
import { useSearchParams } from 'next/navigation';

import { Col, Row } from '@guestyci/foundation/Layout';
import createStyles from '@guestyci/foundation/createStyles';
import TextField from '@guestyci/foundation/TextField';
import Divider from '@guestyci/foundation/Divider';
import t from '@guestyci/localize/t.macro/t.macro';
import useFeatureToggle from '@guestyci/feature-toggle-fe/useFeatureToggle';

import Icon from 'components/Icon';
import Image from 'components/Image';
import PriceConverter from 'components/PriceConverter';
import useGetPathToNavigate from 'hooks/useGetPathToNavigate';

import { isCloudinaryImage } from 'utils';
import { FORCE_THUMBNAIL_WORKAROUND } from 'constants/featureToggleNames';
import { usePropertyTypes } from 'hooks/useConfigSettings';
import useTotalPrice from 'hooks/useTotalPrice';

const useStyles = createStyles(({ breakpoints: { create }, boxShadow }) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    borderRadius: '10px',
    overflow: 'hidden',
    background: '#fff',
    boxShadow: boxShadow[2],
    height: 'auto',
    [create('xs')]: {
      width: 'min-content',
    },
    [create('md')]: {
      width: 320,
    },
    [create('lg')]: {
      width: 381,
    },
  },
  imageWrapper: {
    '& img': {
      width: '100%',
      objectFit: 'cover',
      [create('xs')]: {
        height: 160,
      },
      [create('md')]: {
        height: 240,
      },
    },
  },
  contentWrapper: {
    height: '100%',
    [create('xs')]: {
      padding: 10,
    },
    [create('lg')]: {
      padding: 15,
    },
  },
  titleWrapper: {
    height: '2em',
    whiteSpace: 'normal',
    fontWeight: 'bold',
    lineHeight: 1,

    display: '-webkit-box',
    '-webkit-line-clamp': 2,
    '-webkit-box-orient': 'vertical',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    [create('xs')]: {
      fontSize: 20,
      width: ({ isRecommended }) => (isRecommended ? '10em' : '14em'),
    },
    [create('md')]: {
      fontSize: 22,
    },
    [create('lg')]: {
      fontSize: 24,
      width: ({ isRecommended }) => (isRecommended ? '10em' : '14em'),
    },
  },
  locationWrapper: {
    display: 'flex',
    alignItems: 'center',
    [create('xs')]: {
      marginTop: 5,
    },
    [create('lg')]: {
      marginTop: 10,
    },
  },
  accomodatesWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    '& > div': {
      alignItems: 'center',
    },
    [create('xs')]: {
      padding: '10px 0',
      marginTop: 0,
    },
    [create('lg')]: {
      padding: 0,
      marginTop: 20,
    },
  },
  accomodationItem: {
    display: 'flex',
    [create('xs')]: {
      flexDirection: 'column',
      alignItems: 'center',
      fontSize: 10,
    },
    [create('lg')]: {
      flexDirection: 'column',
      fontSize: 14,
    },
  },
  priceWrapper: {
    alignItems: 'flex-end',
    marginTop: 10,
  },
  priceDataWrapper: {},
  priceTitle: {
    [create('xs')]: {
      fontSize: 12,
    },
    [create('md')]: {
      fontSize: 14,
    },
    [create('lg')]: {
      fontSize: 16,
    },
  },
  perNight: {
    lineHeight: 1.5,
    marginLeft: 10,
  },
  submitButton: {
    width: '100%',
    maxWidth: '100%',
    marginTop: 7,

    [create('xs')]: {
      fontSize: 12,
      padding: '5px 10px!important',
      height: 45,
    },
    [create('lg')]: {
      fontSize: 18,
      width: '100%',
    },
  },
  bottomArrow: {
    marginLeft: 'calc(50% - 10px)',
    width: 0,
    height: 0,
    borderLeft: '10px solid transparent',
    borderRight: '10px solid transparent',
    borderTop: '13px solid #fff',
    marginTop: -1,
    filter: 'drop-shadow(0px 10px 30px rgb(0, 0, 0, 40%))',
    background: 'transparent',
  },
  divider: {
    height: 40,
  },
  recommendedTile: {
    padding: [4, 8],
    backgroundColor: '#E6F3FF',
    color: '#2A57EC',
    fontSize: 12,
  },
}));

const PropertyRecommendedTile = ({ property }) => {
  const { recommendedTile } = useStyles();
  if (!property?.isRecommended) return null;
  const label = t('Recommended');
  return <div className={recommendedTile}>{label}</div>;
};

const SmallPropertyCard = ({ property, handleClick, withArrow = false }) => {
  const searchParams = useSearchParams();
  const search = searchParams.toString();
  const { allPropertyTypes: propertyTypes } = usePropertyTypes();

  const { getPathWithQueryParams } = useGetPathToNavigate();
  const path = getPathWithQueryParams({ destinationPath: `/properties/${property?._id}`, search });

  const [, isForceThumbnal] = useFeatureToggle(FORCE_THUMBNAIL_WORKAROUND);
  const {
    root,
    imageWrapper,
    contentWrapper,
    titleWrapper,
    locationWrapper,
    accomodatesWrapper,
    accomodationItem,
    priceWrapper,
    priceDataWrapper,
    priceTitle,
    submitButton,
    bottomArrow,
    divider,
  } = useStyles({ isRecommended: property?.isRecommended });
  const {
    title,
    address: { country, city },
    picture: { thumbnail } = {},
    pictures,
    accommodates,
    bedrooms,
    bathrooms,
    prices: { basePrice, currency },
    propertyType,
    totalPrice,
  } = property;

  const locationLabel = `${city}, ${country}`;
  const { price, isTotalPriceEnabled } = useTotalPrice({ beforeTaxesPrice: basePrice, totalPrice });
  const propertyTypeLabel =
    Object.values(propertyTypes).find((item) => item.value?.toLowerCase() === propertyType?.toLowerCase())?.label ||
    propertyType;

  const guestsLabel = t('{ num, plural, =0 {# Guests} =1 {1 Guest} few {# Guests} many {# Guests} other {# Guests}}', {
    num: accommodates || 0,
  });
  const bedroomsLabel = t(
    '{ num, plural, =0 {# Bedrooms} =1 {1 Bedroom} few {# Bedrooms} many {# Bedrooms} other {# Bedrooms}}',
    { num: bedrooms || 0 }
  );

  const bathroomLabel = t(
    '{ num, plural, =0 {# Bathrooms} =1 {1 Bathroom} few {# Bathrooms} many {# Bathrooms} other {# Bathrooms}}',
    { num: bathrooms || 0 }
  );
  return (
    <div data-property-row className="small-property-card">
      <div>
        <div className={root}>
          <div className={imageWrapper}>
            <Link
              href={path}
              onClick={handleClick}
            >
              {isForceThumbnal ? (
                <Image
                  alt="alt"
                  src={
                    isCloudinaryImage(pictures[0]?.original)
                      ? pictures[0]?.original
                      : pictures[0]?.thumbnail || thumbnail
                  }
                  h={240}
                  isNewParser
                />
              ) : (
                <Image alt="alt" src={pictures[0]?.original || thumbnail} h={240} />
              )}
            </Link>
          </div>
          <Col className={contentWrapper}>
            <Row justify="between" align="start">
              <TextField className={titleWrapper}>{title}</TextField>
              <PropertyRecommendedTile property={property} />
            </Row>
            <div className={locationWrapper}>
              <Icon className="mr-1" icon="location" size={22} />
              <TextField color="default">{locationLabel}</TextField>
            </div>
            <div className={accomodatesWrapper}>
              {propertyType && (
                <>
                  <div className={accomodationItem}>
                    <Icon icon="hotel" size={25} />
                    <TextField color="secondary">{propertyTypeLabel}</TextField>
                  </div>
                  <Divider orientation="vertical" className={divider} />
                </>
              )}
              <div className={accomodationItem}>
                <Icon icon="guest" size={25} />
                <TextField color="secondary">{guestsLabel}</TextField>
              </div>
              <>
                <Divider orientation="vertical" className={divider} />
                <div className={accomodationItem}>
                  <Icon icon="listing_beds" size={25} />
                  <TextField color="secondary">{bedroomsLabel}</TextField>
                </div>
                <Divider orientation="vertical" className={divider} />
              </>
              <div className={accomodationItem}>
                <Icon icon="listing_bathroom" size={25} />
                <TextField color="secondary">{bathroomLabel}</TextField>
              </div>
            </div>
            <Row justify="between" className={priceWrapper}>
              <div className={priceDataWrapper}>
                <Row align="end">
                  <Col>
                    <TextField className={priceTitle} color="secondary">
                      {t(
                        '{isTotalPriceEnabled, select, true {Total} false {Price per night} other {Price per night}}',
                        {
                          isTotalPriceEnabled,
                        }
                      )}
                    </TextField>
                    <TextField className="font-weight-normal" variant="h2">
                      <PriceConverter currency={currency} amount={price} />
                    </TextField>
                  </Col>
                </Row>
                {!isTotalPriceEnabled && (
                  <TextField variant="h7" color="secondary">
                    {t('Additional charges may apply')}
                  </TextField>
                )}
              </div>
            </Row>
            <Link
              href={path}
              onClick={handleClick}
              className={cn('btn btn-colored', submitButton)}
            >
              {t('View deal')}
            </Link>
          </Col>
        </div>
      </div>
      {withArrow && <div className={bottomArrow} />}
    </div>
  );
};

export default SmallPropertyCard;
