'use client';

import { useLocale } from 'next-intl';
import { usePathname } from 'i18n/routing';
import { useSearchParams } from 'next/navigation';

const useGetPathToNavigate = () => {
  const locale = useLocale();
  const pathname = usePathname();
  const searchParams = useSearchParams();

  const getPathWithQueryParams = ({ destinationPath = pathname, search = null, useCurrentParams = true }) => {
    const searchParamsString = search || (useCurrentParams ? searchParams.toString() : '');
    return `${destinationPath}${searchParamsString ? `?${searchParamsString}` : ''}`;
  }
  return {
    path: pathname,
    locale,
    // INFO: We always allow translations for next js app. Users should have NEW_TRANSLATIONS_UI enabled.
    isTranslationsEnabled: true,
    isFtLoading: false,
    getPathWithLocale: path => path,
    getPathWithQueryParams,
  };
};

export default useGetPathToNavigate;
